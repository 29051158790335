.head {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding-bottom: 65%;
    // .head__img
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .head__wrap
    &__wrap {
        @include container();
        position: absolute;
        left: 50%;
        width: 100%;
        top: calc(30px + (220 - 30) * ((100vw - 320px) / (1920 - 320)));
        transform: translateX(-50%);
        z-index: 20;
    }
    // .head__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    // .head__h1
    &__h1 {
        @extend .h1;
        font-family: $ff-helvetica;
        margin-bottom: 15px;
    }
    // .head__h2
    &__h2 {
        @extend .h2;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
    }
}
