.product {
    background: $color-white-silver;
    // .product__wrap
    &__wrap {
        position: relative;
        @include container();
        padding-top: 90px;
        padding-bottom: 80px;
    }
    // .product__header
    &__header {
        margin-bottom: 70px;
    }
    // .product__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 15px;
        text-align: center;
    }
    // .product__h3
    &__h3 {
        @extend .h3;
        text-align: center;
    }
    // .product__slider
    &__slider {
        position: relative;
    }
    // .product__row
    &__row {
        display: flex;
        @include container();
        margin-right: -10px;
        margin-left: -10px;
    }
    &__prev,
    &__next {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 60px;
        height: 60px;
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 2px;
            background: $color-dark-gray;
        }
    }
    // .product__prev
    &__prev {
        left: -30px;
        &:before {
            transform: rotate(-45deg);
            top: 21px;
        }
        &:after {
            transform: rotate(45deg);
        }
    }
    // .product__next
    &__next {
        right: -30px;
        &:before {
            transform: rotate(45deg);
            top: 21px;
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
    // .product__column
    &__column {
        height: 100%;
        background: $color-white;
    }
    // .product__card
    &__card {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}
.card {
    // .card__content
    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    // .card__h4
    &__h4 {
        @extend .h4;
        flex: 0 1 auto;
        // 2 строки
        min-height: 54px;
        // 3 строки
        // min-height: 73px;
        padding: 16px 16px 0;
        margin-bottom: 10px;
    }
    // .card__description
    &__description {
        flex: 1 1 auto;
        padding: 0 16px 16px;
        font-family: $ff-helvetica;
        font-size: 15px;
        line-height: calc(20 / 15 * 100%);
        color: $color-dark-gray;
        @media (max-width: 768px) {
            font-size: 10px;
            line-height: calc(16 / 10 * 100%);
        }
    }
    // .card__tab
    &__tab {
        flex: 0 1 auto;
    }
    // .card__tab-img
    &__tab-img-box {
        max-height: 253px;
        flex: 0 0 253px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 15px;
    }
    // .card__tab-img
    &__tab-img {
        margin: 0 auto;
        max-width: 250px;
        width: 100%;
        &_active {
            display: block;
        }
        &:not(&_active) {
            display: none;
        }
    }
    // .card__tab-btn-box
    &__tab-btn-box {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    // .card__tab-btn
    &__tab-btn {
        @extend .tab-btn;
        &_active {
            @extend .tab-btn_active;
        }
    }
    // .card__btn-box
    &__btn-box {
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        padding: 0 16px 16px;
    }
    // .card__btn
    &__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        width: 100%;
        font-family: $ff-helvetica;
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        padding: 0 20px;
        font-size: 13px;
        border: 1px solid $color-black;
        white-space: nowrap;
        transition: all 0.3s ease 0s;

        &:hover{
            background-color: $color-black;
            color: $color-white;
        }
    }
}
.slick-track {
    display: flex !important;
}

.slick-slide {
    height: auto !important;
    padding: 0 10px;
}
.slick-slide > div {
    height: 100%;
}
