.product-slider {
    background: $color-white-f3;
    // .product-slider__wrap
    &__wrap {
        @include container(1620px);
        padding-top: 40px;
        padding-bottom: 30px;
    }
    // .product-slider__row
    &__row {
        display: flex;
        @media (max-width:767px) {
            flex-wrap: wrap;
        }
    }
    // .product-slider__column
    &__column {
        flex: 0 1 50%;
        // .product-slider__column_img
        &_img {
            padding: 0 calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
        // .product-slider__column_content
        &_content {
            padding: 0 calc(15px + (80 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
        @media (max-width:767px) {
            flex: 1 1 100%;
            padding: 0;
        }
    }
    // .product-slider__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 10px;
    }
    // .product-slider__h3
    &__h3 {
        @extend .h3;
        margin-bottom: 10px;
    }
    // .product-slider__text
    &__text {
        font-family: $ff-helvetica;
        font-size: 16px;
        line-height: calc(24 / 16 * 100%);
        color: $color-dark-gray;
        margin-bottom: 15px;
    }
    // .product-slider__btn-box
    &__btn-box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15 + 2px;
        border-bottom: 2px solid $color-white-e4;
    }
    // .product-slider__h4
    &__h4 {
        flex: 1 1 100%;
        font-weight: bold;
        font-style: normal;
        @extend .h4;
        margin-bottom: 10px;
    }
    // .product-slider__btn-container
    &__btn-container {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        margin-right: 15px;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        span {
            font-family: $ff-helvetica;
            font-size: 13px;
            color: $color-dark-gray;
        }
        &_active {
            .product-slider__btn {
                @extend .tab-btn_active;
            }
        }
        .product-slider__btn {
            @extend .tab-btn;
        }
    }
    // .product-slider__btn
    &__btn {
    }
    // .product-slider__tab-body
    &__tab-body {
        display: none;
        &_active {
            display: flex;
        }
        @media (max-width:1023px) {
            flex-direction: column-reverse;
        }
    }
    // .product-slider__slider-preview
    &__slider-preview {
        flex: 0 0 80px;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        overflow: auto;
        @media (max-width:1023px) {
            flex-direction: row;
            height: 80px;
            margin: 0;
            max-width: 650px;
        }
        @media (max-width:767px) {
            flex: 0 1 auto;
            margin-bottom: 30px;
        }
    }
    // .product-slider__slider-preview-img
    &__slider-preview-img {
        max-width: 100%;
        margin-bottom: 10px;
        opacity: 0.7;
        cursor: pointer;
        &_active {
            opacity: 1;
            border: 2px solid $color-dark-gray;
        }
        @media (max-width:1023px) {
            height: 80px;
            margin: 0px 15px 0px 0px;
        }
    }
    // .product-slider__slider
    &__slider {
        flex: 0 1 650px;
        max-height: 650px;
        @media (max-width:1023px) {
            flex: 0 1 auto;
            margin-bottom: 10px;
        }
        @media (max-width:767px) {
            max-height: unset;
        }
    }
    // .product-slider__slider-img
    &__slider-img {
        max-width: 100%;
        display: none;
        &_active {
            display: block;
            animation: fadein 1s;
        }
    }
    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
    // .product-slider__link
    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        text-transform: uppercase;
        font-family: $ff-helvetica;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.5px;
        margin-bottom: 45px;
        background: $color-black;
        color: $color-white;
        transition: all 0.3s ease 0s;
        &:hover {
            opacity: 0.8;
        }
    }
    // .product-slider__download
    &__download {
        display: inline-flex;
        align-items: center;
        font-family: $ff-helvetica;
        font-size: 13px;
        color: $color-dark-gray;
        transition: all 0.3s ease 0s;
        &:before {
            content: url(/static/images/download.svg);
            width: 17px;
            height: auto;
            margin-right: 10px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
