$wrap: 1920px;
:root {
    --padding: 15px;
    @media (min-width:768px) {
        --padding: 30px;
    }
}
#wrapper {
    max-width: 100%;
    overflow: hidden;
}
@mixin container($max-width: $wrap, $padding: var(--padding)) {
    @if ($max-width and $padding) {
        max-width: calc(#{$max-width} + (#{$padding} * 2));
        padding-right: #{$padding};
        padding-left: #{$padding};
    } @else if (#{$max-width}) {
        max-width: calc(#{$max-width} + (var(--padding) * 2));
        padding-right: var(--padding);
        padding-left: var(--padding);
    } @else if (#{$padding}) {
        max-width: calc($wrap + (#{$padding} * 2));
        padding: 0 #{$padding};
    } @else {
        max-width: calc($wrap + (var(--padding) * 2));
        padding-right: var(--padding);
        padding-left: var(--padding);
    }
        margin: 0 auto;
}
.h1 {
    font-family: $ff-helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: calc(62 / 50 * 100%);
    color: $color-dark-gray;
    @media (max-width:767px) {
        font-size: 42px;
        line-height: calc(50 / 42 * 100%);
    }
}
.h2 {
    font-family: $ff-helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: calc(50 / 42 * 100%);
    color: $color-dark-gray;
    @media (max-width:767px) {
        font-size: 26px;
        line-height: calc(34 / 26 * 100%);
    }
}
.h3 {
    font-family: $ff-helvetica;
    font-size: 22px;
    line-height: calc(30 / 22 * 100%);
    color: $color-dark-gray;
    @media (max-width:767px) {
        font-size: 16px;
        line-height: calc(24 / 16 * 100%);
    }
}
.h4 {
    font-family: $ff-helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: $color-dark-gray;
    @media (max-width:767px) {
        font-size: 14px;
    }
}
.text {
    font-family: $ff-helvetica;
    font-weight: 300;
    font-size: 22px;
    line-height: calc(30 / 22 * 100%);
    @media (max-width:767px) {
        font-size: 16px;
        line-height: calc(24 / 16 * 100%);
    }
}
.tab-btn {
    position: relative;
    width: 28px;
    height: 28px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-black;
    border-radius: 50%;
    &[data-color="black"] {
        background: $color-black;
    }
    &[data-color="white"] {
        background: $color-white;
    }
    &[data-color="woody"] {
        background: $color-woody;
    }
    &_active {
        &:before,
        &:after {
            content: "";
            position: absolute;
        }
        &[data-color="black"],
        &[data-color="woody"] {
            &:before,
            &:after {
                border: 1px solid $color-white;
                background-color: $color-white;
            }
        }
        &[data-color="white"] {
            &:before,
            &:after {
                border: 1px solid $color-black;
                background-color: $color-black;
            }
        }
        &:before {
            width: 6px;
            height: 2px;
            left: 6px;
            transform: rotate(45deg);
        }
        &:after {
            width: 10px;
            height: 2px;
            left: 10px;
            transform: rotate(-45deg);
        }
    }
}