.map {
    // .map__wrap
    &__wrap {
        @include container();
        padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    }
    // .map__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 65px;
    }
    // .map__h2
    &__h2 {
        @extend .h2;
        margin-bottom: 40px;
    }
    // .map__dropdown
    &__dropdown {
        position: relative;
        display: flex;
        align-items: center;
        &:after {
            content: url(/static/images/arrow.svg);
            position: absolute;
            right: 15px;
            width: 15px;
            top: 15px;
            height: auto;
            pointer-events: none;
        }
        @media (max-width: 599px) {
            flex-direction: column;
            &:after {
                top: 63px;
            }
        }
    }
    // .map__label
    &__label {
        @extend .h3;
        margin-right: 25px;
        @media (max-width: 599px) {
            margin: 0px 0px 25px 0px;
        }
    }
    // .map__select
    &__select {
        @extend .text;
        display: inline-flex;
        align-items: center;
        height: 48px;
        padding: 4px 40px 0 20px;
        border: 1px solid $color-dark-gray;
        appearance: none;
    }
}

.shop {
    // .shop__wrap
    &__wrap {
        @include container(960px, 75px);
        padding-top: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
        @media (max-width:767px) {
            padding-right: 35px;
            padding-left: 35px;
        }
    }
    // .shop__content
    &__content {
        position: relative;
    }
    // .shop__slider
    &__slider {
        position: relative;
    }
    // .shop__btn
    &__btn {
        position: absolute;
        top: 50%;
        width: 24px;
        height: 54px;
        background: no-repeat center url(/static/images/index/map/arrow.svg);
        // .shop__btn_prev
        &_prev {
            left: -60px;
            transform: translateY(-50%) scaleX(-1);
            @media (max-width:767px) {
                left: -25px;
            }
        }
        // .shop__btn_next
        &_next {
            transform: translateY(-50%);
            right: -60px;
            @media (max-width:767px) {
                right: -25px;
            }
        }
    }
    // .shop__item
    &__item {
        text-align: center;
    }
    // .shop__img-link
    &__img-link {
        display: inline-block;
        img {
            max-width:100%;
            height: 145px;
            margin: 0 auto;
            object-fit: contain;
        }
        margin-bottom: 20px;
    }
    // .shop__item-h3
    &__item-h3 {
        @extend .h3;
        margin-bottom: 15px;
    }
    // .shop__item-info
    &__item-info {
        display: flex;
        flex-direction: column;
        *:last-child {
            margin-bottom: 0;
        }
        span {
            display: inline-block;
            margin-bottom: 10px;
        }
        a {
            font-family: $ff-helvetica;
            font-size: 16px;
            color: $color-blue;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
