.banner {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    // .banner__img
    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        @media (max-width:767px) {
            display: none;
        }
    }
    // .banner__img-small
    &__img-small {
        @extend .banner__img;
        display: block;
        @media (min-width:768px) {
            display: none;
        }
    }
    // .banner__wrap
    &__wrap {
        @include container();
        position: absolute;
        padding-top: 15px;
        padding-bottom: 15px;
        background: rgba(255, 255, 255, 0.6);
        left: 50%;
        top: 40px;
        transform: translateX(-50%);
        z-index: 20;
    }
    // .banner__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    // .banner__h2
    &__h2 {
        @extend .h4;
        margin-bottom: 15px;
    }
    // .banner__h1
    &__h1 {
        @extend .h2;
    }
}
